<template>
    <div class="tl xd-dia">
        <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="860px"
        top="20vh"
        :append-to-body="true"
		custom-class="lq6bpfk1"
        @close="closeQueryDialog">
        <template slot="title">
            <!-- 头部图片区 -->
            <div class="dib query">
                <div class="dib img-box"></div>
            </div>
            <div class="dib close" @click="closeQueryDialog">
                <img src="@/assets/img/close.png" alt="">
            </div>
        </template>
            <!-- 标题 -->
            <div class="tc mb15 pr">
                <div class="mb10">
                    <span class="dib fs12i c9 mr10">-----</span>
                    <span class="dib fs20i c_theme fwb vm">{{ ast.kks6y7gx_title[0].body[0].value }}</span>
                    <span class="dib fs12i c9 ml10">-----</span>
                </div>
                <div class="fs12i c9">{{ ast.kks6yhl6_info[0].body[0].value }}</div>
            </div>

        <!-- 表单内容 -->
            <!-- <el-form
                :action="ast.kkt36lwf_queryForm[0].attributeMap.action.value.value"
                :model="queryForm" :rules="queryrules"
                ref="queryForm"
                label-width="70px"
                :label-position="labelPosition"
                class="demo-ruleForm"
                @submit.native="sub_queryForm($event)"
            > -->
            <el-form
                :model="queryForm" :rules="queryrules"
                ref="queryForm"
                label-width="100px"
                :label-position="labelPosition"
                class="demo-ruleForm"
            >
                <div class="tc lq6bxkhy">
                    <!-- 产品信息 -->
                    <div class="dib vt tl lq6c0clq lq6c3p4u_left">
                        <div class="dib fs14 fwb mb15">{{ ast.kkt33b4y_cpxx[0].body[0].value }}</div>
                        <!-- 产品名称 -->
                        <el-form-item :label="ast.kkt384ve_cpmc[0].body[0].value" prop="goods_name">
                            <el-input v-model="queryForm.goods_name"></el-input>
                        </el-form-item>
                        <!-- 同义名 -->
                        <el-form-item :label="ast.kkt3fgiw_tym[0].body[0].value" prop="goods_tym">
                            <el-input v-model="queryForm.goods_tym"></el-input>
                        </el-form-item>
                        <!-- CAS号 -->
                        <el-form-item :label="ast.kkt3h3v6_cas[0].body[0].value" prop="cas">
                            <el-input v-model="queryForm.cas"></el-input>
                        </el-form-item>
                        <!-- 结构式 -->
                        <el-form-item :label="ast.kkt4wsk6_struct[0].body[0].value" prop="struct">
                            <!-- <el-input type="textarea" :rows="3" v-model="queryForm.struct"></el-input> -->
                            <el-upload
                                v-if="is_kmlw38hg"
                                class="upload-demo"
                                :action="ast.kkt553er_action[0].attributeMap.href.value.value"
                                :on-preview="handlePreview"
                                :on-remove="handleRemove"
                                :on-success="handleSuccess"
                                :file-list="fileList"
                                name="jgs"
                                :limit="1"
                                list-type="picture">
                                <el-button size="small" type="primary" class="upload_btn">{{ ast.kkt4x79k_upload[0].body[0].value }}</el-button>
                                <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
                            </el-upload>
                            <div v-else class="kmmr3cwh_struct">
                                <img v-if="kmlwad5k_info.struct == ''" src="@/assets/img/no-picture2.jpg" alt="">
                                <img v-else :src="kmlwad5k_info.struct" alt="">
                            </div>
                        </el-form-item>
                    </div>
                    <!-- 联系方式 -->
                    <div class="dib  tl lq6c0clq lq6c4t9j_right">
                        <div class="fs14 fwb mb15">{{ ast.kkt33li9_lxfs[0].body[0].value }}</div>
                        <!-- 公司 -->
                        <el-form-item :label="ast.kkt3i59g_company[0].body[0].value" prop="company">
                            <el-input v-model="queryForm.company"></el-input>
                        </el-form-item>
                        <!-- 联系人 -->
                        <el-form-item :label="ast.kkt3id4g_lxr[0].body[0].value" prop="lxr">
                            <el-input v-model="queryForm.lxr"></el-input>
                        </el-form-item>
                        <!-- 电话 -->
                        <el-form-item :label="ast.kkt3iicb_mobile[0].body[0].value" prop="mobile">
                            <el-input v-model="queryForm.mobile"></el-input>
                        </el-form-item>
                        <!-- 邮箱 -->
                        <el-form-item :label="ast.kkt3ioih_mail[0].body[0].value" prop="mail">
                            <el-input v-model="queryForm.mail"></el-input>
                        </el-form-item>
                        <!-- 内容 -->
                        <el-form-item :label="ast.kkt3isuu_content[0].body[0].value" prop="content">
                            <el-input type="textarea" :rows="3" v-model="queryForm.content"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <!-- 提交按钮 -->
                <div class="tc" v-show="dialogVisible">
                    <!-- <el-button type="primary" class="sub_btn" native-type="submit">{{ ast.kkt5ik33_submit[0].body[0].value }}</el-button> -->
                    <el-button type="primary" class="sub_btn" @click="sub_form">{{ ast.kkt5ik33_submit[0].body[0].value }}</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            // 自定义验证产品名称
            var checkGoodsName = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(this.ast.kkt5vp7r_mc[0].body[0].value));
                } else {
                callback();
                }
            };

            // 自定义验证cas
            var checkCas = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(this.ast.kkt5vp7r_cas[0].body[0].value));
                } else {
                callback();
                }
            };

            // 自定义验证公司名称
            var checkCompany = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(this.ast.kkt5vp7r_com[0].body[0].value));
                } else {
                callback();
                }
            };

            // 自定义联系人
            var checkLxr = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(this.ast.kkt5vp7r_lxr[0].body[0].value));
                } else {
                    callback();
                }
            };
            // 自定义校验电话
            var checkPhone = (rule, value, callback) => {
                if (!/^[1][3,4,5,7,8][0-9]{9}$/.test(value)) {
                    callback(new Error(this.ast.kkt5vp7r_mobile[0].body[0].value));
                } else {
                    callback();
                }
            };
            // 自定义校验邮箱
            var checkMail = (rule, value, callback) => {
                if (!/^[^@]+@[^@]+$/.test(value)) {
                    callback(new Error(this.ast.kkt5vp7r_mail[0].body[0].value));
                } else {
                    callback();
                }
            };

            // 自定义校验邮箱
            var checkContent = (rule, value, callback) => {
                if (value == '') {
                    callback(new Error(this.ast.kkt5vp7r_detail[0].body[0].value));
                } else {
                    callback();
                }
            };
            return {
                dialogVisible: false,
                is_kmlw38hg:true,
                // 表单信息
                queryForm:{
                    // 产品名称
                    goods_name: '',
                    // 同义名
                    goods_tym: '',
                    // CAS号
                    cas: '',
                    // 结构式
                    struct: '',
                    // 公司
                    company: '',
                    // 联系人
                    lxr: '',
                    // 电话
                    mobile: '',
                    // 邮箱
                    mail: '',
                    // 内容
                    content: '',
                },
                // 表单标签位置 英文版为上下结构
                labelPosition:'left',
                // 表单规则
                queryrules: {
                    // 产品名称
                    goods_name:[
                        { validator: checkGoodsName, trigger: 'blur'  }
                    ],
                    // cas
                    cas:[
                        { validator: checkCas, trigger: 'blur'  }
                    ],
                    // 公司
                    company:[
                        { validator: checkCompany, trigger: 'blur'  }
                    ],
                    // 联系人
                    lxr:[
                        { validator: checkLxr, trigger: 'blur'  }
                    ],

                    // 电话
                    mobile:[
                        { validator: checkPhone, trigger: 'blur'  }
                    ],

                    // 邮箱
                    mail:[
                        { validator: checkMail, trigger: 'blur'  }
                    ],

                    // 内容
                    content:[
                        { validator: checkContent, trigger: 'blur'  }
                    ],

                },
                // 上传结构式
                fileList:[],
            };
        },
        props:{
            kmlwad5k_info: Object
        },
        created(){
            var path = this._routerRoot.url.hostname;
            if(path == 'dev.conier.com'){
                this.labelPosition = 'top';
            }
        },
        mounted(){

            if(/^\/product.*/.test(this.$route.path)){
                this.queryForm.goods_name = this.kmlwad5k_info.name;
                this.queryForm.cas = this.kmlwad5k_info.cas;
                this.queryForm.goods_tym = this.kmlwad5k_info.tym;
                this.is_kmlw38hg = false;
                console.log(this.kmlwad5k_info);
            }else{
                this.is_kmlw38hg = true;
            }
        },
        methods: {
            // 关闭询单dialog
            closeQueryDialog() {
                this.dialogVisible = false;
                // 重置验证信息
                this.$refs.queryForm.resetFields();
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
                this.fileList = fileList;
            },
            handlePreview(file) {
                console.log(file);
            },
            handleSuccess(res) {
                this.fileList.push({
                    name:res.name,
                    url:res.thumb_name
                })
            },
            // 默认提交 验证不通过时不提交
            // sub_queryForm(e){
            //     this.$refs.queryForm.validate((valid) => {
            //         if (!valid) {
            //             e.preventDefault();
            //             return false;
            //         }
            //     });
            // },
            // 接口提交
            sub_form(){
                this.$refs.queryForm.validate((valid) => {
                    if (valid) {
                        this.$post('/ask/save', {
                            cpmc: this.queryForm.goods_name,
                            tym: this.queryForm.goods_tym,
                            cas: this.queryForm.cas,
                            company: this.queryForm.company,
                            tel: this.queryForm.mobile,
                            email: this.queryForm.mail,
                            content: this.queryForm.content,
                            name: this.queryForm.lxr,
                        }).then((rsp) => {
                            if(rsp.data.state != 0){
                                this.$message.error('fail')
                            }else{
                                this.dialogVisible = false;
                                this.$message.success('success')
                            }
                        })

                    }
                });

            }
        }
    };
</script>

<style lang="less">
	@import (reference) "@/assets/less/base.less";
	.lq6bpfk1{
		// reset
		.el-form-item__label {
			flex-shrink: 0;
			white-space: nowrap;
		}
		.el-form-item  {
			display: flex;
		}
		.el-input__inner,.el-textarea__inner {
			width: 100%!important;
		}
		.el-form-item__content {
			flex-grow: 1;
			margin-left: 0!important;
		}
		//
		.lq6bxkhy {
			display: flex;
		}
		//
		.lq6c0clq {
			width: 0;
			flex-grow: 1;
			+.lq6c0clq {
				margin-left: 40px;
			}
		}
		.lq6c3p4u_left {
			// reset
			.el-form-item__label {
				margin-right: 40px;
			}
		}
		.lq6c4t9j_right {
			//
		}
	}

    // .xd-dia{
        .el-dialog{
            border-radius: 5px;
            // reset element
            .el-dialog__header{
                position: relative;
                .tc;
                // 自定义关闭按钮
                .close{
                    position: absolute;
                    right: -30px;
                    top: 0;
                    cursor: pointer;
                    img{
                        width: 20px;
                    }
                }
                // 自定义标题样式
                .query{
                    background-color: #fff;
                    border-radius: 80px;
                    .pa;
                    top: -75px;
                    left: calc(50% - 75px);
                    width: 150px;
                    height: 150px;
                    line-height: 150px;
                    overflow: hidden;
                    .tc;
                    .img-box{
                        background: url('~@/assets/img/query.png') no-repeat;
                        width: 120px;
                        height: 120px;
                        margin-top: 15px;
                        .vm;
                    }
                }
                .el-dialog__headerbtn{
                    display: none;
                }
            }
            .el-dialog__body{
                padding-top: 45px;
                .el-form-item.is-error .el-input__inner{
                    border-color: red;
                }

                .el-form-item.is-error .el-textarea__inner{
                    border-color: red;
                }

                .el-input__inner,.el-textarea__inner{
                    width: 280px;
                    background-color: #f1f1f1;
                    border: 1px solid #f1f1f1;
                    border-radius: 2px;
                }
                .el-upload-list--picture .el-upload-list__item{
                    width: 280px;
                }
                // 上传按钮
                .upload_btn{
                    background-color: #f1f1f1;
                    color: #666;
                    border: none;
                    padding: 5px 20px;
                    border-radius: 2px;
                }
                // 提交按钮
                .sub_btn{
                    width: 250px;
                    .fwb;
                    border-radius: 3px;
                }
                &::-webkit-scrollbar{
						width:6px;
					}

                /* --- 滚动条里面的滚动块 --- */
                &::-webkit-scrollbar-thumb {
                    width: 6px;
                    background-color: #dbdbdb;
                    border-radius: 5px;
                }
                // 获取到的图片盒子设置
                .kmmr3cwh_struct{
                    width: 290px;
                    height: 145px;
                    .tc;
                    img{
                        max-width: 230px;
                        max-height: 135px;
                    }
                }
            }
        }
    // }
</style>
