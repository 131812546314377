import Vue from 'vue';
import Cookie from "js-cookie";
import Axios from 'axios';
import Config from '@/assets/config.json5';
import { parse, walk, SyntaxKind } from "html5parser";
// app base less
import "@/assets/less/base.less";
import JSON5 from 'json5';

// http://vivify.mkcreative.cz/
// import "vivify.css";

// global
Object.assign(global, {
    isDevGetVal: (trueVal, falseVal) => {
        return process.env.NODE_ENV === 'development' ? trueVal : falseVal;
    }
})

// fundebug

// 基础******************************************************************************************************************************************************************************************************************************************
// util
const u = {
    url: {
        // url字符串 转 url对象
        parse: function (url) {
            var reg = /([a-zA-Z]+):\/\/([^/:]+)(:\d+)?(\/[^?#]*)?(\?[^#]*)?(#.*)?/;
            var arr = url.match(reg);
            return {
                source: url,
                protocol: arr[1],
                hostname: arr[2],
                port: arr[3] ? arr[3].slice(1) : "",
                path: arr[4] ? arr[4] : "",
                segments: (function () {
                    if (arr[4]) {
                        return arr[4]
                            .replace(/^\//, "")
                            .replace(/\/$/, "")
                            .split("/");
                    }
                    return "";
                })(),
                search: arr[5] ? arr[5] : "",
                params: function () {
                    var seg = arr[5] ? arr[5] : "";
                    return this.qsToMap(seg.slice(1));
                }.bind(this)(),
                hash: arr[6] ? arr[6].slice(1) : "",
                relative:
                    (arr[4] ? arr[4] : "") +
                    (arr[5] ? arr[5] : "") +
                    (arr[6] ? arr[6] : ""),
            };
        },
        // url对象 转 url字符串
        stringify: function (obj) {
            return [
                obj.protocol,
                "://",
                obj.hostname,
                (function () {
                    return obj.port ? ":" + obj.port : "";
                })(),
                `/${obj.segments.join('/')}`,
                // querystring
                function () {
                    var paramsStr = this.mapToQs(obj.params);
                    return paramsStr && "?" + paramsStr;
                }.bind(this)(),
                (function () {
                    return obj.hash ? "#" + obj.hash : "";
                })(),
            ].join("");
        },
        // Map 转 querystring
        mapToQs: function (map) {
            return [...map].reduce(
                function (sum, item) {
                    var el;
                    if (Array.isArray(item[1])) {
                        el = item[1]
                            .reduce(function (su, ite) {
                                if (ite) {
                                    su.push(item[0] + "=" + ite);
                                } else {
                                    su.push(item[0]);
                                }
                                return su;
                            }, [])
                            .join("&");
                    } else {
                        if (item[1]) {
                            el = item[0] + "=" + item[1];
                        } else {
                            el = item[0];
                        }
                    }
                    sum.push(el);
                    return sum;
                },
                []
            ).join("&");
        },
        // querystring 转 Map
        qsToMap: function (qs) {
            var ret = new Map(),
                seg = qs.split("&"),
                len = seg.length,
                i = 0,
                s;
            for (; i < len; i++) {
                if (!seg[i]) {
                    continue;
                }
                s = seg[i].split("=");
                ret.has(s[0]) ? ret.get(s[0]).push(s[1]) : ret.set(s[0], [s[1]]);
            }
            ret.forEach(function (item, key) {
                if (item.length == 1) {
                    ret.set(key, item[0]);
                }
            });
            return ret;
        },
        // querystring 编码
        qsEncode: function (qs) {
            var map = u.url.qsToMap(qs);
            map.forEach(function (val, key) {
                if (Array.isArray(val)) {
                    val.forEach(function (item, index) {
                        val[index] = encodeURIComponent(item);
                    });
                } else {
                    map.set(key, encodeURIComponent(val));
                }
            });
            return this.mapToQs(map);
        },
        // querystring 解码
        qsDecode: function (qs) {
            var map = u.url.qsToMap(qs);
            map.forEach(function (val, key) {
                if (Array.isArray(val)) {
                    val.forEach(function (item, index) {
                        val[index] = decodeURIComponent(item);
                    });
                } else {
                    map.set(key, decodeURIComponent(val));
                }
            });
            return this.mapToQs(map);
        },
        // qs 替换
        qsUpdate: function (url, qsObj) {
            var urlObj = this.parse(url);
            Object.keys(qsObj).forEach(function (item) {
                urlObj.params.set(item, qsObj[item]);
            });
            return this.stringify(urlObj);
        },
        // qs 整个替换
        qsUpdateAll(url, qsStr) {
            var urlObj = this.parse(url);
            urlObj.params = this.qsToMap(qsStr);
            return this.stringify(urlObj);
        },
        // qs 只保留
        qsInclude(url, obj) {
            var urlObj = this.parse(url);
            urlObj.params.forEach((val, key, map) => {
                if (key in obj) {
                    if (typeof obj[key] === 'boolean') {
                        if (!obj[key]) {
                            map.delete(key);
                        }
                    } else if (obj[key] != val) {
                        map.delete(key);
                    }
                } else {
                    map.delete(key);
                }
            })
            return this.stringify(urlObj);
        },
    },
    json: {
        // 将非标准的JSON字符串标准化
        normalize: function (str) {
            return str
                .replace(/[\s\t\r\n]/g, "")
                .replace(/'/g, '"')
                .replace(/([{,])([^"]+?):/g, '$1"$2":')
                .replace(/,\s*([\]}])/g, "$1");
        },
    },
    util: {
        // setTimeout 模拟 setInterval
        setInterval: function (func, ms) {
            var more = arguments.slice(2);
            var id;
            var stop = false;
            var exec = function () {
                if (!stop) {
                    var res = func.apply(this, more);
                    if (!res || !/promise/i.test(res.constructor.name)) {
                        throw new Error("please return Promise");
                    }
                    res.then(function () {
                        id = setTimeout(exec, ms);
                    });
                }
            };
            id = setTimeout(exec, ms);
            return {
                clearInterval: function () {
                    stop = true;
                    clearTimeout(id);
                },
            };
        },
        // 解析 html
        parseHTML: function (html) {
            console.time("ast");
            var waitRemove = [];
            var ast = parse(html, {
                setAttributeMap: true,
            });
            walk(ast, {
                enter: function (node, parent) {
                    node.parent = parent;
                    // html 源码字符串
                    // node.src = html;
                    switch (node.type) {
                        // 文本节点
                        case SyntaxKind.Text:
                            var trimVal = node.value.trim();
                            // if (node.value.replace(/(^\s*)|(\s*$)/g, '') === '') {
                            // 处理-空文本节点
                            if (trimVal === "") {
                                waitRemove.push({
                                    node: node,
                                    parent: parent,
                                });
                            }
                            // 非空文本 trim
                            else {
                                node.value = trimVal;
                                node.rawHtml = node.value;
                                node.toString = function () {
                                    return this.value;
                                }
                            }
                            break;
                        // 其他节点
                        case SyntaxKind.Tag:
                                // 注释节点
                            if (["!--"].indexOf(node.name) !== -1) {
                                waitRemove.push({
                                    node: node,
                                    parent: parent,
                                });
                            } else {
                                // 有 id 属性的 node，提到根节点来
                                if (node.attributeMap.id) {
                                    ast[node.attributeMap.id.value.value] = node;
                                }
                                // 有 class 属性的 node，提到根节点来
                                if (node.attributeMap.class) {
                                    var arr = node.attributeMap.class.value.value
                                        .replace(/\s+/, " ")
                                        .split(" ");
                                    for (var i = 0; i < arr.length; i++) {
                                        if (ast[arr[i]]) {
                                            ast[arr[i]].push(node);
                                        } else {
                                            ast[arr[i]] = [node];
                                        }
                                    }
                                }
                                // 附加 rawHtml 属性
                                if (node.close) {
                                    node.rawHtml = html.slice(node.open.start, node.close.end);
                                } else {
                                    node.rawHtml = html.slice(node.open.start, node.open.end);
                                }
                                // 有cid，则设置属性到父对象的body上
                                let cid = node.attributeMap.cid?.value?.value;
                                if (cid) {
                                    parent.body[cid] = node;
                                }
                                // 重写所有 attributes 的 toString
                                node.attributes.forEach(item => {
                                    item.toString = function () {
                                        return this.value.value;
                                    }
                                })
                                // 根据 data-mime 确定是否需要格式化为 JSON5
                                switch (node.attributeMap['data-mime']?.value?.value) {
                                    case 'json5':
                                        node.json = JSON5.parse(node.body[0].value);
                                        break;
                                }
                                // node 的 toString
                                node.toString = function () {
                                    let div = document.createElement('div');
                                    div.innerHTML = this.rawHtml;
                                    return div.innerText.trim();
                                }
                            }
                            break;
                    }
                },
            });
            // 遍历删除无用的 node
            for (var i = 0; i < waitRemove.length; i++) {
                if (waitRemove[i].parent) {
                    waitRemove[i].parent.body.splice(
                        waitRemove[i].parent.body.indexOf(waitRemove[i].node),
                        1
                    );
                } else {
                    ast.splice(ast.indexOf(waitRemove[i].node), 1);
                }
            }
            console.timeEnd("ast");
            return ast;
        },
        // 遍历 tree 节点
        // 深度优先
        walkTree({
            tree = {},
            childrenKey = 'children',
            enter,
            leave
        }) {
            let end = false;
            let endWalk = function () {
                end = true;
            }
            let walk = function (node, parent, indexArr) {
                // 遍历子节点之前
                enter && enter(...arguments, endWalk);
                // 深度迭代
                for (let i in node[childrenKey]) {
                    if (Object.prototype.hasOwnProperty.call(node[childrenKey], i) && !end) {
                        let tmp = [...indexArr, i];
                        walk(node[childrenKey][i], node, tmp);
                    }
                }
                // 遍历子节点之后
                leave && leave(...arguments, endWalk);
            }
            if (Array.isArray(tree)) {
                for (let i in tree) {
                    if (!end) {
                        walk(tree[i], undefined, [i]);
                    }
                }
            } else {
                walk(tree, undefined, []);
            }
        },
        // 格式化 毫秒时间戳 为 YY-MM-DD
        formatDay (mill) {
            var d = new Date(mill);
            return [
                d.getFullYear(),
                '-',
                ('0' + (d.getMonth()+1)).slice(-2),
                '-',
                ('0' + d.getDate()).slice(-2),
            ].join('')
        },
    },
};[]
// localStorage: 版本号
const v = `kiptuyny`;
const local = {
    get: () => {
        let obj = JSON.parse(localStorage.getItem("wx") || "{}");

        if (!obj.v || parseInt(obj.v, 36) < parseInt(v, 36)) {
            local.set({
                v,
            });
            return local.get();
        }
        local.value = obj;
        return obj;
    },
    set: (obj) => {
        local.value = obj;
        localStorage.setItem("wx", JSON.stringify(obj));
    },
};
Object.assign(local, {
    value: local.get()
})
// ast
const ast = u.util.parseHTML([...document.querySelectorAll(".data")].map(i => {
    return i.innerHTML;
}).join(''));
// url
const url = (() => {
    let obj = u.url.parse(location.toString());
    let qs = u.url.mapToQs(obj.params);
    obj.params = u.url.qsToMap(u.url.qsDecode(qs));
    return obj;
})();
// 组合******************************************************************************************************************************************************************************************************************************************
const protoData = {
    $ELEMENT: { size: "small" },
    // https://github.com/js-cookie/js-cookie#readme
    $cookie: Cookie,
    // 跨组件通信事件总线
    $bus: new Vue(),
    $axios: Axios,
    $Vue: Vue,
    $config: Config,
    $get(url, params) {
        return Axios.get(url, {
            params
        })
    },
    $post(url, params) {
        return Axios.post(url, params)
    },
    $u: u,
};
const mixinData = {
    ast,
    url,
    page,
    local,
    // 异步加载的组件
    coms: [],
};
// 导出******************************************************************************************************************************************************************************************************************************************
export {
    protoData,
    mixinData
};
