<template>
    <div id="app">
        <!-- main -->
        <router-view></router-view>
        <index-right></index-right>
        <!-- 异步组件，比如弹窗 -->
        <div>
            <component
                v-for="(item, index) in coms"
                :key="index"
                :is="item"
            ></component>
        </div>
    </div>
</template>

<script>
import indexRight from "@/components/include/index-right.vue";
// 入口文件
export default {
    name: "kl_app",
    components: { indexRight },
    beforeCreate() {
        // 生产环境，删除一些DOM
        if (process.env.NODE_ENV === "production") {
            var n = document.querySelectorAll(".data,.node_remove");
            for (var i = 0; i < n.length; i++) {
                n[i].parentNode.removeChild(n[i]);
            }
        }
    },
};
</script>

<style lang="less">
@import (reference) "@/assets/less/base.less";
.el-icon-more {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
    background-color: #f8f8f8;
}
</style>
