import { protoData, mixinData } from "./init.js";
import Vue from "vue";
import router from "./router";
import Axios from 'axios';
// elementui
import { Button, Autocomplete, Switch, Table, TableColumn, Input, DatePicker, Popover, Tree, Select, Option, Checkbox, Pagination, Dialog, Dropdown, DropdownMenu, DropdownItem, Carousel, CarouselItem, Form, FormItem, Upload, Message, Icon } from "@/assets/element-ui";
import "@/assets/element-ui/packages/theme-chalk/src/base.scss";
Vue.prototype.$message = Message;
Vue.config.productionTip = false;
Vue.use(Button);
Vue.use(Autocomplete);
Vue.use(Switch);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Input);
Vue.use(DatePicker);
Vue.use(Popover);
Vue.use(Tree);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Upload);
Vue.use(Icon);


// Axios
// Object.assign(Axios.defaults, {
// 	baseURL: 'http://localhost:8000/api'
// })
// 添加响应拦截器
// Axios.interceptors.request.use(function (config) {
// 	switch (config.url) {
// 		case '/':
// 			// var source = Axios.CancelToken.source();
// 			// config.cancelToken = source.token;
// 			// try {
// 			// 	source.cancel('123');
// 			// } catch (e) {
// 			// 	console.log(e);
// 			// }
// 			return {};
// 		// break;
// 	}

// 	return config;
// });
Axios.interceptors.response.use(function (res) {
	// let httpCode = res.status.toString();
	// let wxCode;
	// switch (httpCode.slice(0, 1)) {
	//     case '2':
	//         wxCode = res.data.code;
	//         // res
	//         switch (wxCode) {
	//             case 1:
	//                 break;
	//         }
	//         break;
	// }
	return res;
}, function (err) {
	console.log(err);
	return Promise.reject(err);
});
// 使用******************************************************************************************************************************************************************************************************************************************
Object.assign(Vue.prototype, protoData);
Vue.mixin({
	data() {
		return {
			...mixinData,
			window,
		};
	},
	created() {
		let name = this.$options.name;
		if (name) {
			if (this.$root[name]) {
				this.$root[name].push(this);
			} else {
				this.$root[name] = [this];
			}
		}
	},
	beforeDestroy() {
		let name = this.$options.name;
		if (name) {
			let index = this.$root[name].indexOf(this);
			if (index != -1) {
				this.$root[name].splice(index, 1);
			}
		}
	}
});
export default {
	newVue({ entry }) {
		return new Promise(resolve => {
			let createApp = () => {
				let app = new Vue({
					router,
					render: (h) => h(entry),
					created() {
						// 执行一次，附加全局变量
						if (process.env.NODE_ENV === "development") {
							window.app = this;
						} else {
							window.kir1d2d7 = this;
						}
					},
					mounted() {
						// 动态附加-鼠标手势
						// (async () => {
						// 	let { default: smartGesture } = await import("gh-smart-gesture");
						// 	this.$Vue.prototype.$gesture = new smartGesture({
						// 		timeDelay: 0,
						// 		activeColor: '',
						// 		onSwipe: list => {
						// 			this.$bus.$emit('kjpu7umi_swipe', list);
						// 		},
						// 		onGesture: (rst, points) => {
						// 			this.$bus.$emit('kjpu9grt_gesture', [rst, points]);
						// 		}
						// 	});
						// })()
					}
				}).$mount('#app');

				return app;
			}
			// mock
			if (process.env.NODE_ENV === 'development') {
				if (mixinData.url.params.has('nomock')) {
					resolve(createApp());
				} else {
					import('./mock').then(() => {
						resolve(createApp());
					});
				}
			} else {
				if (mixinData.url.params.has('mock')) {
					import('./mock').then(() => {
						resolve(createApp());
					});
				} else {
					resolve(createApp());
				}
			}
		});
	}
}
