<template>
    <div class="tal_right">
        <img
            :class="gzh_isVis ? '' : 'dn'"
            class="gzh_img"
            src="@/assets/img/gzh.png"
            alt=""
        />
        <ul class="right_list">
            <li class="fs14 r_top" @click="to_top(30, 20)">
                {{ ast.kkuvf27o_top.body[0].value }}
            </li>
            <li class="fs14" @click="handle_dialog">
                {{ ast.kkuua7yc_xd.body[0].value }}
            </li>
            <li
                class="fs14"
                @mouseenter="gzh_isVis = true"
                @mouseleave="gzh_isVis = false"
            >
                {{ ast.kkuua7yc_kf.body[0].value }}
            </li>
            <li class="kn5udqnw_li fs14">
                <a href="/aboutus/contact" class="fs14 cw">{{
                    ast.kkuua7yc_fk.body[0].value
                }}</a>
            </li>
        </ul>
        <inquiry-sheet ref="queryDialog"></inquiry-sheet>
    </div>
</template>

<script>
import inquirySheet from "./inquiry-sheet.vue";
export default {
    components: { inquirySheet },
    data() {
        return {
            gzh_isVis: false,
        };
    },
    methods: {
        // 处理子组件 -询单
        handle_dialog() {
            this.$refs.queryDialog.dialogVisible = true;
        },
        // 回到顶部 【step 步长 seconds 运行一次的间隔】
        to_top(step, seconds) {
            // documentElement 是返回 document 的直接子元素 html
            document.documentElement.scrollTop -= step;
            if (document.documentElement.scrollTop > 0) {
                var time = setTimeout(() => {
                    this.to_top(step);
                }, seconds);
            } else {
                clearTimeout(time);
            }
        },
    },
};
</script>

<style lang="less">
@import (reference) "@/assets/less/base.less";
.tal_right {
    z-index: 999;
    position: fixed;
    top: 40%;
    right: 0;
    .gzh_img {
        width: 90px;
        margin-bottom: 5px;
        position: absolute;
        top: -92px;
    }
    .right_list {
        border: 1px solid @c_theme;
        li {
            background-color: @c_theme;
            color: #fff;
            padding: 10px 30px;
            // border: 1px solid #eee;
            border-bottom: 1px solid #eee;
            .tc;
            cursor: pointer;
            &:hover {
                background-color: #fff;
                color: @c_theme;
                // border-top: 1px solid @c_theme;
                // border-bottom: 0;
                &:last-child {
                    border-bottom: none;
                }
                a {
                    .c_theme;
                }
            }
            &:last-child {
                border-bottom: none;
            }
        }
        .r_top {
            padding-top: 1px;
            &::before {
                content: "^";
                .db;
                font-size: 13px;
                text-align: center;
                position: relative;
                top: 6px;
                left: -3px;
            }
        }
    }
}
#body .kn5udqnw_li {
    padding: 0;
    a {
        .dib;
        padding: 10px 30px;
    }
}
</style>
