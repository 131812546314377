import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export default new VueRouter({
	mode: 'history',
	routes: [
		// 首页
		{
			path: '/',
			component: () => import("@/components/index/index.vue"),
		},
		// 产品-详情
		{
			path: '/product/:detail',
			component: () => import("@/components/product/product-detail.vue"),
		},
		// 产品
		{
			path: '/product*',
			component: () => import("@/components/product/product-list.vue"),
		},
		// 关于我们-公司介绍
		{
			path: '/aboutus',
			component: () => import("@/components/about/introduce.vue"),
		},
		// 关于我们-公司文化
		{
			path: '/aboutus/culture',
			component: () => import("@/components/about/culture.vue"),
		},

		// 关于我们-服务优势
		{
			path: '/aboutus/advantage',
			component: () => import("@/components/about/advantage.vue"),
		},
		// 关于我们-联系我们
		{
			path: '/aboutus/contact',
			component: () => import("@/components/about/contact.vue"),
		},
		// 技术问答-详情
		{
			path: '/technicalqa/:read',
			component: () => import("@/components/qa/qa-read.vue"),
		},
		// 技术问答-列表
		{
			path: '/technicalqa*',
			component: () => import("@/components/qa/qa-list.vue"),
		},
		// 目录集-登录号
		{
			path: '/cas_list*',
			component: () => import("@/components/directory/dlh.vue"),
		},
		// 目录集-化工词汇
		{
			path: '/glossary*',
			component: () => import("@/components/directory/hgch.vue"),
		},
		// 目录集-化学名称总汇
		{
			path: '/name_list',
			component: () => import("@/components/directory/mc-zh.vue"),
		},
		// 目录集-登录号列表
		{
			path: '/cas/:list',
			component: () => import("@/components/directory/dlh-list.vue"),
		},
		// 目录集-化学名称列表
		{
			path: '/name/:mclist',
			component: () => import("@/components/directory/mc-list.vue"),
		},
		//  标签页
		// {
		// 	path: '/tagpage/:id',
		// 	component: () => import("@/components/tag/tag.vue"),
		// },

		// 新闻-行业新闻
		{
			path: '/news/industrynews*',
			component: () => import("@/components/news/news-industrynews.vue"),
		},
		// 新闻-详情
		{
			path: '/news/:read',
			component: () => import("@/components/news/news-read.vue"),
		},
		// 新闻-列表
		{
			path: '/news*',
			component: () => import("@/components/news/news-list.vue"),
		},
		// 标签页2
		{
			path: '/tag*',
			component: () => import("@/components/tag/tag.vue"),
		},
		// 新闻-技术问答
		// {
		// 	path: '/news/tech',
		// 	component: () => import("@/components/news/news-tech.vue"),
		// },

		// // 404 页面
		{
			path: '*',
			component: () => import('@/components/other/wx-page-other-404.vue')
		}
	]
})
